













import { Component, Vue } from "vue-property-decorator";
import VesselAccessControlWrapper from "@/components/knockoutWrappers/VesselAccessControlWrapper.vue";
import events from "App/events";
import htmlEncoder from "Utilities/htmlencode-helper";

@Component({
  components: {
    VesselAccessControlWrapper,
  },
})
export default class VesselAccessControl extends Vue {
  vesselAccessGroupOnCreatedBinding: any = null;
  vesselAccessGroupOnUpdatedBinding: any = null;
  vesselAccessGroupOnDeletedBinding: any = null;

  message(action: string, vesselGroupName: string): { text: string; color: string } {
    let text = `Vessel access group ${htmlEncoder.htmlEncode(vesselGroupName)}' has been `;
    switch (action) {
      case "createdVesselAccessGroup":
        text += "<b>created</b>";
        break;

      case "updatedVesselAccessGroup":
        text += "<b>updated</b>";
        break;

      case "deletedVesselAccessGroup":
        text += "<b>deleted</b>";
        break;
    }

    return {
      text: text,
      color: "success",
    };
  }

  mounted(): void {
    this.vesselAccessGroupOnCreatedBinding = events.vesselAccessGroupOnCreated.add((accessGroup: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", this.message("createdVesselAccessGroup", accessGroup.name));
    });

    this.vesselAccessGroupOnUpdatedBinding = events.vesselAccessGroupOnUpdated.add((accessGroup: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", this.message("updatedVesselAccessGroup", accessGroup.name));
    });

    this.vesselAccessGroupOnDeletedBinding = events.vesselAccessGroupOnDeleted.add((accessGroup: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", this.message("deletedVesselAccessGroup", accessGroup.name));
    });
  }

  beforeDestroy(): void {
    this.vesselAccessGroupOnCreatedBinding.detach();
    this.vesselAccessGroupOnUpdatedBinding.detach();
    this.vesselAccessGroupOnDeletedBinding.detach();
  }
}
